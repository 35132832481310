.blackout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: block;
}
.spinner:empty {
  position: absolute;
  top: calc(50% - 1em);
  left: calc(50% - 1em);
  width: 2em;
  height: 2em;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-left: 4px solid #113FB4;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}