.ant-select, .ant-select-selector {
  @apply !rounded-sm w-full;
  min-width: 100px !important;
}

span.ant-select-clear {
  @apply w-3 h-3 !flex items-center justify-center !bg-gray-100;
}

.matr-cron {
  @apply flex flex-col gap-2;
  /* display: flex;
  align-items: flex-start;
  flex-wrap: wrap; */
}
.matr-cron > div,
.matr-cron-field {
  @apply flex flex-col w-full items-start text-sm;
  /* display: flex;
  align-items: center; */
}
.matr-cron-field {
  /* @apply mb-4; */
  /* margin-bottom: 10px; */
}
.matr-cron-field > span {
  @apply mb-1.5 text-slate-600 font-medium;
  /* margin-left: 5px; */
}
div.matr-cron-select .ant-select-selector {
  @apply !bg-gray-100 !border-0 h-8;
  /* margin-left: 5px; */
}
.matr-cron-select.matr-cron-select-no-prefix {
  @apply ml-0;
  /* margin-left: 0; */
}
div.matr-cron-error .matr-cron-select .ant-select-selector {
  @apply bg-gray-100 !rounded-sm;
  /* border-color: #ff4d4f;
  background: #fff6f6; */
}
div.matr-cron-custom-select {
  @apply bg-gray-100 border-0;
  min-width: 100px;
  /* z-index: 1; */
  /* background: white; */
}
div.matr-cron-error div.matr-cron-custom-select {
  background: #fff6f6;
}
div.matr-cron-select.matr-cron-custom-select.ant-select
  div.ant-select-selector {
    @apply pl-2 pr-10 bg-transparent !rounded-sm;
  /* padding-left: 11px;
  padding-right: 30px;
  background: transparent; */
}
.matr-cron-read-only
  div.matr-cron-select.matr-cron-custom-select.ant-select
  div.ant-select-selector {
  @apply pr-2
}
div.matr-cron-custom-select .ant-select-selection-search {
  width: 0 !important;
  margin: 0 !important;
}
div.matr-cron-custom-select .ant-select-selection-placeholder {
  position: static;
  top: 50%;
  right: auto;
  left: auto;
  transform: none;
  transition: none;
  opacity: 1;
  color: inherit;
}
.matr-cron-week-days-placeholder
  .matr-cron-custom-select
  .ant-select-selection-placeholder,
.matr-cron-month-days-placeholder
  .matr-cron-custom-select
  .ant-select-selection-placeholder {
  opacity: 0.4;
}
.matr-cron-custom-select-dropdown {
  @apply !rounded-sm;
  min-width: 0 !important;
  width: 200px !important;
}
.matr-cron-custom-select-dropdown .rc-virtual-list {
  max-height: none !important;
}
.matr-cron-custom-select-dropdown-grid .rc-virtual-list-holder-inner {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
}
.matr-cron-custom-select-dropdown-grid
  .rc-virtual-list-holder-inner
  .ant-select-item-option-content {
  text-align: center;
}
.matr-cron-custom-select-dropdown-hours-twelve-hour-clock {
  @apply !rounded-sm;
  width: 260px !important;
}
.matr-cron-custom-select-dropdown-minutes-large {
  @apply !rounded-sm;
  width: 300px !important;
}
.matr-cron-custom-select-dropdown-minutes-large
  .rc-virtual-list-holder-inner {
  grid-template-columns: repeat(7, 1fr);
}
.matr-cron-custom-select-dropdown-minutes-medium {
  @apply !rounded-sm;
  width: 215px !important;
}
.matr-cron-custom-select-dropdown-minutes-medium
  .rc-virtual-list-holder-inner {
  grid-template-columns: repeat(5, 1fr);
}
.matr-cron-period > span:first-child {
  @apply flex flex-col !ml-0 font-medium text-slate-600;
  /* margin-left: 0 !important; */
}
.matr-cron-period
  .matr-cron-select.ant-select-single.ant-select-open
  .ant-select-selection-item {
  @apply flex flex-col opacity-100 !rounded-sm;
  /* opacity: 1; */
}
.matr-cron-select-dropdown-period {
  @apply !rounded-sm;
  min-width: 0 !important;
  width: auto !important;
}
.matr-cron-clear-button {
  /* @apply flex h-full items-end justify-end; */
  /* margin-left: 10px;
  margin-bottom: 10px; */
}
.matr-cron-disabled .matr-cron-select.ant-select-disabled {
  background: #f5f5f5;
}
div.matr-cron-select.matr-cron-custom-select.ant-select
  div.ant-select-selector
  > .ant-select-selection-overflow {
  align-items: center;
  flex: initial;
}
div.matr-cron-select:not(
    .ant-select-disabled
  ).matr-cron-custom-select.ant-select
  div.ant-select-selector
  > .ant-select-selection-overflow
  > .ant-select-selection-overflow-item:first-child {
  /* z-index: -1; */
}