.react-datepicker__input-container input {
  @apply block w-full text-base md:text-sm bg-slate-200 border border-gray-300 rounded-md shadow-sm
}

.react-datepicker__input-container .simple input {
  @apply block w-full text-base md:text-sm bg-gray-100 h-9 border-0 rounded-md focus:outline-none px-2 shadow-none focus:ring
}

.react-datepicker__input-container .filter_date_input input {
  @apply text-sm py-1.5 px-2 flex w-full border-0 bg-slate-100 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:outline-none rounded-md;
  height: 32px;
  min-height: 32px;
}

.react-datepicker-popper {
  @apply z-40 w-52 text-sm bg-white shadow px-3 py-2 border-gray-200 rounded-lg;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important
}

.react-datepicker__portal {
  @apply absolute z-10 w-52 text-sm transform-none bg-white shadow px-3 mx-3 py-2 top-12 right-0 border-2 border-gray-200 rounded-lg
}

.react-datepicker__month-container {
  @apply flex flex-col
}

.react-datepicker__month {
  @apply flex flex-col
}

.react-datepicker__current-month {
  @apply ml-2.5 text-sm font-semibold text-gray-800
}

.react-datepicker__week {
  @apply flex justify-around
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs
}

.react-datepicker__day-name {
  @apply w-6 h-6 flex items-center justify-center py-1 rounded-full
}

.react-datepicker__navigation {
  @apply absolute top-2
}

.react-datepicker__navigation--previous {
  @apply right-12 w-6 h-6 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__navigation--next {
  @apply right-4 w-6 h-6 rounded transition flex items-center justify-center hover:bg-gray-200
}

.react-datepicker__day {
  @apply mb-1 w-6 h-6 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded-sm
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent
}

.react-datepicker__day--outside-month {
  @apply text-gray-300
}

.react-datepicker__day--in-range {
  @apply bg-gray-200
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
}

.react-datepicker-time__input-container {

}

.react-datepicker-time__input .react-datepicker-time__input {
  @apply w-full px-2 py-1 text-sm border-0 bg-slate-100 rounded-sm
}

.react-datepicker-wrapper {
  @apply h-full flex items-center w-full;
}

.react-datepicker__input-container {
  @apply h-full flex items-center w-full rounded-md;
}

.react-datepicker__input-container input {
  @apply
  !text-sm 
  border-0
  form-input 
  py-2
  px-2
  block 
  w-full 
  rounded-sm 
  ring-1
  ring-slate-200
  bg-slate-50 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
}

.react-datepicker__input-container input.narrow {
  @apply
  !text-sm 
  border-0
  form-input 
  py-1
  px-2
  h-[28px]
  block 
  w-full 
  rounded-sm 
  ring-1
  ring-slate-200
  bg-slate-50 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
}

.react-datepicker__input-container input.cell {
  @apply
  !text-sm 
  border-0
  py-1.5
  px-2
  block 
  w-full 
  outline-none
  ring-slate-200
  bg-slate-50 text-slate-800 focus:bg-white;
}
/* Убираем надпись Time */
.react-datepicker-time__caption {
  @apply hidden
}

/* .react-datepicker__input-container input.matr-input{
  @apply h-full flex items-center w-full px-2 outline-none bg-slate-100 border-0 rounded-md focus:bg-white py-2;
} */