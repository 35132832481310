
/* OWN */
.rdg {
    @apply 
    bg-slate-50;
  }
  
  .rdg.custom {
    @apply
    border-t
    border-gray-300
    border;
    /* height: 100%;
    contain: none; */
  }
  
  .rdg-cell {
    @apply 
    !outline-indigo-700
    bg-inherit
    border-b
    border-r
    p-0;
    contain: style;
  }
  
  .rdg-cell.rdg-cell-dragged-over {
    @apply 
    bg-indigo-100;
  }
  .rdg-manage-cell .rdg-cell-drag-handle {
    visibility: hidden;
  }
  .rdg-cell-drag-handle {
    @apply bg-indigo-800;
  }
  
  .rdg-group-row .rdg-cell {
    @apply overflow-visible;
    contain: none;
  }
  
  .rdg-summary-row .rdg-cell {
    @apply
    border-0
    overflow-visible
    bg-slate-200;
  }
  
  .rdg-summary-row {
    @apply
    w-screen
    hover:bg-slate-200
    bg-slate-200;
  }
  
  .rdg-header-row {
    @apply
    bg-gray-50
    border-b
    w-screen;
    contain: none;
  }
  
  .rdg-header-row .rdg-cell {
    @apply
    outline-none
    outline-0
    outline-offset-0
    bg-slate-100
    ;
  }
  
  .rdg-header {
    @apply
    pl-0
    pr-0
    bg-inherit
    flex;
    z-index: 3;
    contain: none;
    overflow: visible;
  }
  
  .rdg-header-row .rdg-cell.rdg-cell-frozen {
    z-index: 9;
  }
  
  .rdg-header-row .rdg-cell {
    z-index: 8;
  }
  
  .rdg-row .rdg-cell-frozen {
    z-index: 4;
  }
  
  .rdg-row .rdg-cell.rdg-manage-cell {
    @apply 
    outline-none
    outline-0
    outline-offset-0;
  }
  
  .rdg-row .rdg-cell-frozen {
    z-index: 5;
  }
  
  .rdg-row .rdg-cell-frozen.rdg-editor-container {
    z-index: 6;
  }
  
  .rdg-row {
    @apply
    bg-white;
    contain: none;
  }
  
  .rdg-row:hover {
    @apply bg-slate-50;
  }
  
  .rdg-row[aria-selected='true'] {
    @apply bg-indigo-50;
  }
  
  .rdg-row[aria-selected='true']:hover {
    @apply bg-indigo-200;
  }
  
  .rdg-editor-container {
    @apply
    overflow-visible;
    z-index: 2;
    contain: none;
  }
  
  .rdg-header[aria-selected=true] {
    @apply
    shadow-none
    bg-indigo-200;
  }
  
  .rdg-header::after {
    @apply
    hover:bg-slate-500
    relative
    w-1;
  }
  .rdg-header::after:hover {
    @apply
    bg-blue-500;
  }