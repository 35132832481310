.telegram_bg_color {
  background-color: var(--tg-theme-bg-color, white);
}

.telegram_text_color {
  color: var(--tg-theme-text-color);
}

.telegram_hint_color {
  color: var(--tg-theme-hint-color);
}

.telegram_link_color {
  color: var(--tg-theme-link-color);
}

.telegram_button_color {
  background-color: var(--tg-theme-button-color);
}

.telegram_button_text_color {
  color: var(--tg-theme-button-text-color);
}

.telegram_secondary_bg_color {
  background-color: var(--tg-theme-secondary-bg-color);
}