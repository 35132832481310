@tailwind base;
@tailwind components;
@tailwind utilities;


h1 {
  @apply text-xl;
}

h2 {
  @apply text-lg;
}

h3 {
  @apply text-base;
}

h4 {
  @apply text-sm;
}

h5 {
  @apply text-xs;
}

h6 {
  @apply text-xxs;
}

.loader {
  border-top-color: #3b82f6;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.matr_select_fields__control {
  @apply 
  flex 
  flex-wrap 
  items-center
  justify-between
  outline-none
  relative
  box-border 
  w-full 
  py-0.5
  shadow-none
  bg-gray-100
  rounded-sm border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50
  text-sm;
}

.matr_select_fields__menu-list .matr_select_fields__control {
  @apply
  rounded-none
  ring-0
  shadow-none
  !important;
}

.matr_select_fields__control--is-focused {
  @apply 
  outline-none
  bg-white
  border-0
  ring
  ring-indigo-200 
  ring-opacity-50
  !important;
}
.matr_select_fields__indicator-separator {
  width: 0 !important;
}
.matr_select_fields__indicator {
  @apply
  text-gray-600;
}

.matr_select_fields__value-container {
  @apply
  flex
  items-center
  flex-1
  flex-wrap
  text-black
  overflow-hidden
  px-2
  box-border
  relative
  text-sm;
}
.matr_select_fields__single-value {
  @apply
  text-black !important;
}
.matr_select_fields__indicator {
  @apply
  text-indigo-600 !important;
}

.matr_select_fields__indicator-separator {
  @apply
  bg-indigo-600 !important;
}

.matr_select_fields__menu {
  @apply
  rounded
  shadow
  overflow-hidden
  border-0 !important;
}

.matr_select_fields__menu .matr_select_fields__menu-list {
  @apply
  px-0
  py-0;
}

.matr_select_fields__menu-list .matr_select_fields__option {
  @apply
  text-sm
  text-gray-600
  hover:text-indigo-600
  hover:bg-indigo-100
  focus:text-indigo-600
  focus:bg-indigo-100;
}

.matr_select_fields__menu-list .matr_select_fields__option.matr_select_fields__option--is-selected {
  @apply
  text-white
  bg-indigo-600;
}

.matr_select_fields__menu-list .matr_select_fields__option.matr_select_fields__option--is-focused {
  @apply
  text-indigo-600
  bg-indigo-100;
}


/* Small */

.matr_select_fields_small__control {
  @apply 
  flex 
  items-center
  justify-between
  outline-none
  relative
  box-border 
  w-full 
  h-8
  shadow-none
  bg-gray-100
  rounded-sm border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50
  text-sm;
}

.matr_select_fields_small__menu-list .matr_select_fields_small__control {
  @apply
  rounded-none
  ring-0
  shadow-none
  !important;
}

.matr_select_fields_small__control--is-focused {
  @apply 
  outline-none
  bg-white
  border-0
  ring
  ring-indigo-200 
  ring-opacity-50
  !important;
}
.matr_select_fields_small__indicator-separator {
  width: 0 !important;
}
.matr_select_fields_small__indicator {
  @apply
  text-gray-600;
}

.matr_select_fields_small__control .matr_select_fields_small__value-container {
  @apply
  flex
  items-center
  flex-1
  flex-wrap
  text-black
  overflow-hidden
  p-0
  px-3
  box-border
  relative
  text-sm;
}

.matr_select_fields_small__control .matr_select_fields_small__value-container--is-multi {
  @apply
  flex
  items-center
  flex-1
  flex-wrap
  text-black
  overflow-hidden
  p-0
  px-0.5
  box-border
  relative
  text-sm;
}

.matr_select_fields_small__single-value {
  @apply
  text-black !important;
}
.matr_select_fields_small__indicators .matr_select_fields_small__indicator {
  @apply
  p-1
  text-indigo-600;
}

.matr_select_fields_small__indicator-separator {
  @apply
  bg-indigo-600 !important;
}

.matr_select_fields_small__menu {
  @apply
  rounded
  shadow
  overflow-hidden
  border-0 !important;
}

.matr_select_fields_small__menu .matr_select_fields_small__menu-list {
  @apply
  px-0
  py-0;
}

.matr_select_fields_small__menu-list .matr_select_fields_small__option {
  @apply
  text-sm
  text-gray-600
  hover:text-indigo-600
  hover:bg-indigo-100
  focus:text-indigo-600
  focus:bg-indigo-100;
}

.matr_select_fields_small__menu-list .matr_select_fields_small__option.matr_select_fields_small__option--is-selected {
  @apply
  text-white
  bg-indigo-600;
}

.matr_select_fields_small__menu-list .matr_select_fields_small__option.matr_select_fields_small__option--is-focused {
  @apply
  text-indigo-600
  bg-indigo-100;
}
/* Base */

.matr_select_base__control {
  @apply 
  flex 
  flex-wrap 
  items-center
  justify-between
  outline-none
  relative
  box-border 
  w-full
  placeholder-gray-600 
  bg-gray-100
  text-sm;
}
/* Custom */

.matr_select_custom__control {
  @apply 
  flex 
  flex-wrap 
  items-center
  justify-between
  outline-none
  relative
  box-border 
  w-full 
  py-0.5
  shadow-none
  placeholder-gray-500 
  bg-gray-100
  rounded-sm border-0 focus:bg-white focus:ring focus:ring-indigo-200 focus:ring-opacity-50
  text-sm;
}
.matr_select_custom__control--is-focused {
  @apply 
  outline-none
  bg-white
  border-0
  ring
  ring-indigo-200 
  ring-opacity-50
  !important;
}
.matr_select_custom__indicator-separator {
  width: 0 !important;
}
.matr_select_custom__indicator {
  @apply
  text-gray-600;
}

.matr_select_custom__value-container {
  @apply
  flex
  items-center
  flex-1
  flex-wrap
  text-black
  overflow-hidden
  px-2
  py-0.5
  box-border
  relative
  text-sm
}
.matr_select_custom__single-value {
  @apply
  text-black !important;
}
.matr_select_custom__indicator {
  @apply
  text-indigo-600 !important;
}

.matr_select_custom__indicator-separator {
  @apply
  bg-indigo-600 !important;
}

.matr_select_custom__menu {
  @apply
  rounded
  shadow
  overflow-hidden
  border-0 !important;
}

.matr_select_custom__menu .matr_select_custom__menu-list {
  @apply
  px-0
  py-0;
}

.matr_select_custom__menu-list .matr_select_custom__option {
  @apply
  text-sm
  text-gray-600
  hover:text-indigo-600
  hover:bg-indigo-100
  focus:text-indigo-600
  focus:bg-indigo-100;
}

.matr_select_custom__menu-list .matr_select_custom__option.matr_select_custom__option--is-selected {
  @apply
  text-white
  bg-indigo-600;
}

.matr_select_custom__menu-list .matr_select_custom__option.matr_select_custom__option--is-focused {
  @apply
  text-indigo-600
  bg-indigo-100;
}


/* FILTERS SELECT */

.filters .matr_select_filters__control {
  @apply 
  flex 
  flex-wrap 
  items-center
  justify-between
  outline-none
  relative
  box-border 
  border-0
  w-full 
  shadow-none
  bg-gray-100
  rounded-sm
  text-sm;
  height: 32px;
  min-height: 32px;
}


.filters .matr_select_filters__menu-list .matr_select_filters__control {
  @apply
  rounded-none
  ring-0
  shadow-none;
}

.filters .matr_select_filters__control--is-focused {
  @apply 
  outline-none
  bg-white
  border-0
  ring
  ring-indigo-200 
  ring-opacity-50;
}
.filters .matr_select_filters__indicator-separator {
  width: 0 !important;
}

.filters .matr_select_filters__indicators {
  @apply
  flex
  items-center
  h-full
  w-6;
}

.filters .matr_select_filters__indicator {
  @apply
  flex
  items-center
  justify-center
  p-0
  h-4
  w-4
  text-gray-600;
}

.filters .matr_select_filters__value-container {
  @apply
  items-center
  text-black 
  overflow-hidden
  px-2
  box-border
  relative
  text-sm;
}

.filters .matr_select_filters__single-value {
  @apply
  text-black !important;
}


.filters .matr_select_filters__indicator-separator {
  @apply
  bg-indigo-600 !important;
}

.filters .matr_select_filters__menu {
  @apply
  rounded
  shadow
  overflow-hidden
  border-0 !important;
}

.filters .matr_select_filters__menu .matr_select_filters__menu-list {
  @apply
  min-w-min
  px-0
  py-0;
}

.filters .matr_select_filters__menu-list .matr_select_filters__option {
  @apply
  text-sm
  text-gray-600
  hover:text-indigo-600
  hover:bg-indigo-100
  focus:text-indigo-600
  focus:bg-indigo-100;
}

.filters .matr_select_filters__menu-list .matr_select_filters__option.matr_select_filters__option--is-selected {
  @apply
  text-white
  bg-indigo-600;
}

.filters .matr_select_filters__menu-list .matr_select_filters__option.matr_select_filters__option--is-focused {
  @apply
  text-indigo-600
  bg-indigo-100;
}

/* Matr forms select */


.matr_forms_select__control {
  @apply 
  flex 
  flex-wrap 
  items-center
  justify-between
  outline-none
  relative
  box-border 
  w-full 
  py-0.5
  shadow-none
  bg-white
  border
  border-slate-300
  rounded-sm focus:bg-white focus:ring-2 focus:ring-indigo-200
  text-sm;
}

.matr_forms_select__menu-list .matr_forms_select__control {
  @apply
  rounded-none
  ring-0
  shadow-none
  !important;
}

.matr_forms_select__control--is-focused {
  @apply 
  outline-none
  bg-white
  border-0
  ring-4
  ring-indigo-200 
  ring-opacity-50
  !important;
}
.matr_forms_select__indicator-separator {
  width: 0 !important;
}
.matr_forms_select__indicator {
  @apply
  text-gray-600;
}

.matr_forms_select__value-container {
  @apply
  flex
  items-center
  flex-1
  flex-wrap
  text-black
  overflow-hidden
  px-2
  box-border
  relative
  text-sm;
}
.matr_forms_select__single-value {
  @apply
  text-black !important;
}
.matr_forms_select__indicator {
  @apply
  text-indigo-600 !important;
}

.matr_forms_select__indicator-separator {
  @apply
  bg-indigo-600 !important;
}

.matr_forms_select__menu {
  @apply
  rounded
  shadow
  overflow-hidden
  border-0 !important;
}

.matr_forms_select__menu .matr_forms_select__menu-list {
  @apply
  px-0
  py-0;
}

.matr_forms_select__menu-list .matr_forms_select__option {
  @apply
  text-sm
  text-gray-600
  hover:text-indigo-600
  hover:bg-indigo-100
  focus:text-indigo-600
  focus:bg-indigo-100;
}

.matr_forms_select__menu-list .matr_forms_select__option.matr_forms_select__option--is-selected {
  @apply
  text-white
  bg-indigo-600;
}

.matr_forms_select__menu-list .matr_forms_select__option.matr_forms_select__option--is-focused {
  @apply
  text-indigo-600
  bg-indigo-100;
}


/* Matr forms select */

.matr_select_form__control {
  @apply 
  text-sm
  !rounded-sm
  ring-1
  ring-slate-200
!bg-slate-50
  focus:ring-2 
  focus:ring-indigo-200;
}

.matr_select_form__menu-list .matr_select_form__control {
  @apply
  rounded-none
  ring-0
  shadow-none
  !important;
}

.matr_select_form__control--is-focused {
  @apply 
  !bg-white
  !border
  !rounded-sm
  ring-2
  ring-indigo-200;
}
.matr_select_form__indicator-separator {
  width: 0 !important;
}
.matr_select_form__indicator {
  @apply
  text-slate-600;
}

.matr_select_form__value-container {
  @apply
  flex
  items-center
  flex-1
  flex-wrap
  !rounded-md
  text-slate-600
  overflow-hidden
  px-2
  box-border
  relative
  text-sm;
}
.matr_select_form__single-value {
  @apply
  text-black !important;
}
.matr_select_form__indicator {
  @apply
  text-indigo-600 !important;
}

.matr_select_form__indicator-separator {
  @apply
  bg-indigo-600 !important;
}

.matr_select_form__menu {
  @apply
  rounded-md
  shadow
  overflow-hidden
  !border-0;
}

.matr_select_form__menu .matr_select_form__menu-list {
  @apply
  px-0
  !rounded-md
  py-0;
}

.matr_select_form__menu-list .matr_select_form__option {
  @apply
  text-sm
  text-gray-600
  hover:text-indigo-600
  hover:bg-indigo-100
  focus:text-indigo-600
  focus:bg-indigo-100;
}

.matr_select_form__menu-list .matr_select_form__option.matr_select_form__option--is-selected {
  @apply
  text-white
  bg-indigo-600;
}

.matr_select_form__menu-list .matr_select_form__option.matr_select_form__option--is-focused {
  @apply
  text-indigo-600
  bg-indigo-100;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Expression */

.formula {
  @apply my-2 rounded border-none text-sm;
}

.formula--singleLine .formula__control {
  @apply
  p-2
  flex
  w-full
  /* display: inline-block;
  width: 130px; */
}
.formula--singleLine .formula__highlighter {
  @apply
  text-blue-600
  /* padding: 1px;
  border: 2px inset transparent; */
}
.formula--singleLine .formula__input {
  @apply
  text-sm 
  border-0
  form-input 
  py-2 
  block 
  w-full 
  rounded-sm 
  /* dark:bg-slate-700 dark:text-slate-100 dark:focus:bg-slate-600 dark:focus:ring-2 dark:focus:ring-indigo-600 dark:focus:ring-opacity-50  */ 
  !bg-slate-100 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
  ;
  /* padding: 1px;
  border: 2px inset; */
}

.formula--multiLine .formula__control {
  @apply
  flex
  text-sm
  bg-gray-100
  focus:bg-white 
  border-none;
  min-height: 63px;
}
.formula--multiLine .formula__highlighter {
  @apply border-none p-2.5 bg-transparent;
  min-height: 63px;
}
.formula--multiLine .formula__input {
  @apply
  p-2.5 
  block 
  w-full 
  rounded-sm
  bg-gray-100 
  border-0 
  focus:bg-white 
  focus:ring 
  focus:ring-indigo-200 
  focus:ring-opacity-50
  outline-none
  text-sm
  border-none;
  min-height: 63px;
}


.formula__suggestions {
  @apply w-full block bg-white text-xs rounded-sm overflow-auto ;
  position: relative !important;
  top: 10px !important;
  margin-top: 0 !important;
  max-height: 240px;
}

.formula__suggestions__list {
  @apply w-full block bg-white text-xs rounded-sm;
  /* background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt; */
}

.formula__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.formula__suggestions__item--focused {
  @apply bg-indigo-100; 
}

.formula__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}

/* Main styles */
.matr-input {
  @apply
  text-sm 
  border-0
  form-input 
  py-2
  px-2
  block 
  w-full 
  rounded-sm 
  ring-1
  ring-slate-200
  bg-slate-100 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
}

.matr-form-input {
  @apply
  text-sm 
  border-0
  form-input 
  py-2
  px-2
  block 
  w-full 
  h-[34px]
  min-h-[34px]
  rounded-sm 
  ring-1
  ring-slate-200
  bg-slate-50 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
}


.matr-form-dynamic-input {
  @apply
  text-sm 
  border-0
  form-input 
  py-1
  px-2
  w-full 
  h-[34px]
  min-h-[34px]
  rounded-sm 
  ring-1
  ring-slate-200
  flex items-center 
  overflow-hidden 
  whitespace-pre
  active:cursor-text
  bg-slate-50 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
}

.form .ProseMirror {
  @apply
  text-sm 
  border-0
  form-input 
  py-2
  px-2
  min-h-[250px]
  leading-[24px]
  block 
  w-full 
  rounded-sm 
  ring-1
  ring-slate-200
  bg-slate-50 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
}

.full .ProseMirror {
  @apply
  text-sm 
  rounded-md
  overflow-y-scroll
  p-3
  outline-none
  bg-white
  w-full
  leading-[24px]
  h-full;
}

.single .ProseMirror {
  @apply
  text-sm 
  border-0
  form-input 
  py-2
  px-2
  flex 
  w-full 
  h-[34px]
  min-h-[34px]
  rounded-sm 
  ring-1
  ring-slate-200
  bg-slate-50 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
}

.cell .ProseMirror {
  @apply
  text-sm 
  w-full
  h-full
  outline-none;
}

.ProseMirror ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  padding: 0 1rem;
}

.ProseMirror ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}

.ProseMirror ul[data-type="taskList"] p{
  margin: 0;
}

.ProseMirror ul[data-type="taskList"] li{
  display: flex;
}

.ProseMirror ul[data-type="taskList"] li label{
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

.ProseMirror ul[data-type="taskList"] li div{
  flex: 1 1 auto;
}

.ProseMirror ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  padding: 0 1rem;
}

.ProseMirror .is-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* .ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
} */

.block-section-editor .ProseMirror {
  @apply focus:outline-none
}

.block-section-editor-floating-menu {
  @apply flex flex-col items-start bg-white shadow rounded px-2 text-base
}

.block-section-editor-bubble-menu {
  @apply flex
}

.matr-token-input {
  @apply bg-blue-200 text-blue-600 px-1.5 py-1 rounded-md font-medium; 
  box-decoration-break: clone;
}

.matr-form-narrow-input {
  @apply
  text-sm 
  border-0
  form-input 
  py-1
  h-[28px]
  min-h-[28px]
  px-2
  block 
  w-full 
  rounded-sm 
  ring-1
  ring-slate-200
  bg-slate-50 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
}

.matr-label {
  @apply
  font-medium text-slate-600 text-sm;
}

/* Buttons */

.matr-button-sm {
  @apply px-4 py-1.5 rounded-md text-sm text-center font-medium flex items-center justify-center;
}

.matr-button {
  @apply px-4 py-2 rounded-md text-sm text-center font-medium flex items-center justify-center;
}


.rgl-container{
  position: relative;
}
.rgl-bg{
  position: absolute;
  height: 100%;
  left: 10px;
  top: 0;
  right: 10px;
  background: linear-gradient(90deg, #f5f5f5 4.16666667%, transparent 4.16666667%, transparent 8.33333333%, #f5f5f5 8.33333333%, #f5f5f5 12.5%, transparent 12.5%, transparent 16.66666667%, #f5f5f5 16.66666667%, #f5f5f5 20.83333333%, transparent 20.83333333%, transparent 25%, #f5f5f5 25%, #f5f5f5 29.16666667%, transparent 29.16666667%, transparent 33.33333333%, #f5f5f5 33.33333333%, #f5f5f5 37.5%, transparent 37.5%, transparent 41.66666667%, #f5f5f5 41.66666667%, #f5f5f5 45.83333333%, transparent 45.83333333%, transparent 50%, #f5f5f5 50%, #f5f5f5 54.16666667%, transparent 54.16666667%, transparent 58.33333333%, #f5f5f5 58.33333333%, #f5f5f5 62.5%, transparent 62.5%, transparent 66.66666667%, #f5f5f5 66.66666667%, #f5f5f5 70.83333333%, transparent 70.83333333%, transparent 75%, #f5f5f5 75%, #f5f5f5 79.16666667%, transparent 79.16666667%, transparent 83.33333333%, #f5f5f5 83.33333333%, #f5f5f5 87.5%, transparent 87.5%, transparent 91.66666667%, #f5f5f5 91.66666667%, #f5f5f5 95.83333333%, transparent 95.83333333%);
}

/* REACT DATEPICKER */
/* @import './styles/grid_view/index_40.css'; */
@import './styles/grid_view/own.css';
@import './styles/datepicker/datepicker.css';
@import './styles/calendar/styles.css';
@import './styles/cron_expression/styles.css';
@import './styles/tagify/styles.css';
@import './styles/telegram/styles.css';