.matr_tagify.tagify {
  @apply
  text-sm 
  border-0
  form-input 
  py-1
  px-2
  w-full 
  h-[34px]
  min-h-[34px]
  rounded-sm 
  ring-1
  ring-slate-200
  flex items-center 
  flex-nowrap
  overflow-hidden 
  whitespace-pre
  active:cursor-text
  bg-slate-50 text-slate-600 focus:bg-white focus:ring-2 focus:ring-indigo-200;
}

.matr_tagify.tagify--mix {

}

.matr_tagify.tagify--mix .tagify__input {
  @apply p-[3px];
}

.tagify__dropdown{
  @apply bg-white p-3 rounded-md shadow-lg border;
}

.tagify__dropdown[placement=top]{
  margin-top:0;
  transform:translateY(-100%)
}
.tagify__dropdown[placement=top] .tagify__dropdown__wrapper{
  border-top-width:1.1px;border-bottom-width:0
}
.tagify__dropdown[position=text]{
  @apply shadow-lg text-sm;
}
.tagify__dropdown[position=text] .tagify__dropdown__wrapper{
  @apply border-0
}

.tagify__dropdown__wrapper{
  @apply max-h-[300px]
  overflow-auto
  overflow-x-hidden
  bg-white
  border
  shadow-lg;
  transition:.25s cubic-bezier(0,1,.5,1)
}

.tagify__dropdown__header:empty{ @apply hidden; }
.tagify__dropdown__footer{
  @apply hidden;
  /* display:inline-block;
  margin-top:.5em;
  padding:var(--tagify-dd-item-pad);
  font-size:.7em;
  font-style:italic;
  opacity:.5 */
}
.tagify__dropdown__footer:empty{
  @apply hidden;
  /* display:none */
}
.tagify__dropdown--initial .tagify__dropdown__wrapper{
  max-height:20px;transform:translateY(-1em)
}
.tagify__dropdown--initial[placement=top] .tagify__dropdown__wrapper{
  transform:translateY(2em)
}
.tagify__dropdown__item{
  @apply p-2 m-0.5 cursor-pointer relative rounded-sm outline-none max-h-[60px] w-full;
  box-sizing:border-box;
}
.tagify__dropdown__item--active{
  @apply bg-blue-600 text-white;
}
.tagify__dropdown__item:active{
  filter:brightness(105%)
}
.tagify__dropdown__item--hidden{
  padding-top:0;
  padding-bottom:0;
  margin:0 1px;
  pointer-events:none;
  overflow:hidden;
  max-height:0;
  /* transition:var(--tagify-dd-item--hidden-duration,.3s)!important */
}
.tagify__dropdown__item--hidden>*{
  transform:translateY(-100%);
  opacity:0;
  transition:inherit
}

.matr_tagify.tagify--noTags {

}